import React from 'react'
import {Icon, Text} from "@shopify/polaris";
import {
  EditIcon,
  DuplicateIcon,
  DeleteIcon,
  DisabledIcon,
  CheckCircleIcon,
  ReturnIcon,
} from "@shopify/polaris-icons";
import styled from 'styled-components'

const ActionBlock = styled.div`
   display: flex;
  
  .Polaris-Icon {
    margin: 0 10px 0 0 
  }
`;

const actionsIcons = {
  edit: EditIcon,
  duplicate: DuplicateIcon,
  disable: DisabledIcon,
  enable: CheckCircleIcon,
  delete: DeleteIcon,
  restore: ReturnIcon,
};

const OfferActionItem = ({actionType, actionTitle}) => {
  return (
    (<ActionBlock>
      <Icon tone={actionType === 'delete' ? "critical" : "base"} source={actionsIcons[actionType]}/>
      <Text variation={actionType === 'delete' && 'negative'}>{actionTitle}</Text>
    </ActionBlock>)
  );
};

export default OfferActionItem;
